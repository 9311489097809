<template>
  <div>
    <KTCodePreview v-bind:title="'In tem phụ'">
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          @click="printData"
          style="width: max-content"
        >
          <i class="fas fa-print"></i>
          In tem
        </b-button>
      </template>
      <template v-slot:preview>
        <b-row>
          <b-col md="2">
            <b-form-group>
              <label>Bạn cần in:</label>
              <b-form-select
                class="select-style"
                v-model="selectTypePrint"
                :options="printType"
                size="sm"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="required-control">
              <label> Tên sản phẩm: </label>
              <b-form-input
                size="sm"
                v-model="productModel.name"
                placeholder="Nhập tên sản phẩm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="required-control">
              <label> Model: </label>
              <b-form-input
                size="sm"
                v-model="productModel.model"
                placeholder="Nhập tên sản phẩm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <label> Màu sản phẩm: </label>
              <b-form-input
                size="sm"
                v-model="productModel.color"
                placeholder="Nhập màu sản phẩm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <label>Năm sản xuất: </label>
              <b-form-input
                size="sm"
                v-model="productModel.year"
                placeholder="Nhập năm sản xuất"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <label>Địa chỉ NK và PP: </label>
              <b-form-input
                size="sm"
                v-model="productModel.providerAddress"
                placeholder="Nhập địa chỉ NK và NPP"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2" class="pt-4">
            <div class="content-info d-flex justify-content-end">
              <b-button
                  @click="addToList"
                  variant="primary"
                  size="sm"
                  class="font-weight-bolder"
              >
                <i class="flaticon2-add-1"></i>
                Thêm vào danh sách
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-table :items="items" :fields="fields" bordered hover>
          <template v-slot:cell(type)="row">
            <span v-if="row.item.type === VERTU_TEM_TYPE.SIGNATURE"
              >Tem Signature</span
            >
            <span v-if="row.item.type === VERTU_TEM_TYPE.ASTER_P"
              >Tem Aster P</span
            >
            <span v-if="row.item.type === VERTU_TEM_TYPE.IVERTU"
              >Tem iVertu</span
            >
            <span v-if="row.item.type === VERTU_TEM_TYPE.META_BASIC"
              >Tem Meta Basic</span
            >
            <span v-if="row.item.type === VERTU_TEM_TYPE.META_PRO"
              >Tem Meta Pro</span
            >
            <span v-if="row.item.type === VERTU_TEM_TYPE.META_WATCH"
              >Tem Vertu Meta Watch</span
            >
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <v-icon
                small
                class="text-danger"
                @click="showDeleteAlert(row.item.id)"
                v-b-tooltip
                title="Xóa"
                >mdi-delete</v-icon
              >
            </div>
          </template>
        </b-table>
        <h1>Xem trước</h1>
        <div id="printMe">
          <div v-for="(item, index) in items" :key="index">
            <div class="item-print">
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Tên</span>
                  <span class="text-content-color">{{ item.name.trim() }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Model</span>
                  <span class="text-content-color">{{
                    item.model.trim()
                  }}</span>
                </b-row>
              </b-col>
              <b-col v-if="item.type !== VERTU_TEM_TYPE.SIGNATURE">
                <b-row class="text-nowrap">
                  <span class="label-color">Màu sắc</span>
                  <span class="text-content-color">{{
                    item.color.trim()
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Thiết kế bởi</span>
                  <span class="text-content-color"
                    >Vertu International Corporation Limited</span
                  >
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Năm sản xuất</span>
                  <span class="text-content-color">{{item.year}}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Thông số kỹ thuật</span>
                  <span class="text-content-color">{{
                    item.technicalInfo.specification
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Camera sau</span>
                  <span class="text-content-color">{{
                    item.technicalInfo.rearCamera
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Camera trước</span>
                  <span class="text-content-color">{{
                    item.technicalInfo.fontCamera
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Hướng dẫn sử dụng</span>
                  <span class="text-content-color">Xem tài liệu bên trong</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Hướng dẫn bảo quản</span>
                  <span class="text-content-color"
                    >Để nơi khô ráo, nhẹ tay, dễ vỡ</span
                  >
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Thành phần</span>
                  <span class="text-content-color">{{
                    item.technicalInfo.composition
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Nhà sản xuất</span>
                  <span class="text-content-color"
                    >Vertu International Corporation Limited</span
                  >
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Địa chỉ nhà sản xuất</span>
                  <span class="text-content-color"
                    >10th 25 St Thomas Street, Winchester, Hampshire, United
                    Kingdom, SO23 9HJ</span
                  >
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Tra cứu bảo hành</span>
                  <span class="text-content-color"
                    >Thẻ giấy hoặc tra cứu online vertuvietnam.vn</span
                  >
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Kích thước</span>
                  <span class="text-content-color">{{
                    item.technicalInfo.size
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Trọng lượng</span>
                  <span class="text-content-color">{{
                    item.technicalInfo.weight
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Nhà NK và PP</span>
                  <span class="text-content-color"
                    >Công Ty TNHH Công Nghệ và Truyền Thông VMG</span
                  >
                </b-row>
              </b-col>
              <b-col>
                <b-row class="text-nowrap">
                  <span class="label-color">Địa chỉ NK và PP</span>
                  <span class="text-content-color"
                    >{{ item.providerAddress }}</span
                  >
                </b-row>
              </b-col>
            </div>
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { makeToastFaile } from '@/utils/common';
import Swal from 'sweetalert2';
import { v4 } from 'uuid';

const VERTU_TEM_TYPE = {
  SIGNATURE: 1,
  ASTER_P: 2,
  IVERTU: 3,
  META_BASIC: 4,
  META_PRO: 5,
  META_VERTU_2: 6,
  META_WATCH: 7,
};

export default {
  data() {
    return {
      productModel: {
        name: '',
        color: '',
        model: '',
        year: '',
        providerAddress: '',
      },
      printType: [
        {
          id: VERTU_TEM_TYPE.SIGNATURE,
          name: 'Tem Signature',
        },
        {
          id: VERTU_TEM_TYPE.ASTER_P,
          name: 'Tem Aster P',
        },
        {
          id: VERTU_TEM_TYPE.IVERTU,
          name: 'Tem iVertu',
        },
        {
          id: VERTU_TEM_TYPE.META_BASIC,
          name: 'Tem Meta Basic',
        },
        {
          id: VERTU_TEM_TYPE.META_PRO,
          name: 'Tem Meta Pro',
        },
        {
          id: VERTU_TEM_TYPE.META_VERTU_2,
          name: 'Tem Meta Vertu 2',
        },
        {
          id: VERTU_TEM_TYPE.META_WATCH,
          name: 'Tem Meta Watch',
        },
      ],
      selectTypePrint: VERTU_TEM_TYPE.SIGNATURE,
      fields: [
        {
          key: 'type',
          label: 'Loại tem',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
        },
        {
          key: 'name',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
        {
          key: 'model',
          label: 'Model',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '30%',
          },
        },
        {
          key: 'color',
          label: 'Màu',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      items: [],
      VERTU_TEM_TYPE: VERTU_TEM_TYPE,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'In tem phụ sản phẩm' },
    ]);
  },
  components: {
    KTCodePreview,
  },
  computed: {},
  methods: {
    printData() {
      if (!this.items.length) {
        return makeToastFaile('Vui lòng chọn sản phẩm cần in!');
      }
      if (!document.getElementById('printMe')) {
        return;
      }
      const prtHtml = document.getElementById('printMe').innerHTML;
      const width = 150;
      const height = 60;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <link rel="stylesheet" href="main.css" />
          <link rel="stylesheet" media="print" href="print.css" />
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Arsenal:wght@400&display=swap" rel="stylesheet">
          ${stylesHtml}
          <style>
            @page {
            size: ${width}mm ${height}mm;
            }
            @media print {
              html,
              body {
                width: ${width}mm;
                height: ${height}mm;
                line-height: 1.2 !important;
                font-family: 'Roboto';
                color: rgb(3, 3, 3);
              }
            }
            .item-print {
              width: 150mm !important;
              height: 60mm !important;
              margin-top: 30px !important;
              margin-left: 30px !important;
              display: inline-table;
            }
            span {
              font-family: 'Roboto';
            }
            .label-color {
              color: rgb(3, 3, 3);
              font-weight: 500 !important;
              width: 120mm !important;
              font-size: 11px !important;
            }
            .text-content-color {
              color: rgb(3, 3, 3);
              font-weight: 500 !important;
              white-space: pre-wrap;
              font-size: 12px !important;
              padding-right: 10px;
              width: 360px !important;
            }
          </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
    },
    getTechnicalInfo() {
      if (this.selectTypePrint === this.VERTU_TEM_TYPE.SIGNATURE) {
        return {
          specification:
            'wifi + 4G, RAM: 3GB, ROM: 32GB, Bluetooth v4.1 +LE, Linux based feature phone',
          rearCamera: 'Không',
          fontCamera: 'Không',
          composition:
            'Điện thoại, bao da, sạc, cáp, pin, sách hướng dẫn, thẻ xác nhận, thẻ bảo hành',
          size: '130mm x 42mm x 13mm',
          weight: '166-238g',
        };
      }
      if (this.selectTypePrint === this.VERTU_TEM_TYPE.ASTER_P) {
        return {
          specification:
            'wifi + 4G, 4.97" Screen, 1080P, RAM: 6GB, ROM: 128GB, Bluetooth 5.0, CPU: Qualcomm® Snapdragon ™',
          rearCamera: '16MP',
          fontCamera: '20MP',
          composition:
            'Điện thoại, sạc, cáp, sách hướng dẫn, thẻ xác nhận, thẻ bảo hành',
          size: '149.8mm x 71mm x 10.1mm',
          weight: '220g',
        };
      }
      if (this.selectTypePrint === this.VERTU_TEM_TYPE.META_BASIC) {
        return {
          specification:
            'wifi + 4G, 6,67’’ Screen, 1080 x 2400 pixels 144Hz, Ram : 12GB, Rom : 512GB, Bluetooth v5.2, CPU Qualcomm Snapdragon 8 Gen 1',
          rearCamera: 'Chính 64 MP & Phụ 50 MP, 8 MP',
          fontCamera: '16 MP',
          composition:
            'Điện thoại, sạc, cáp, sách hướng dẫn, thẻ xác nhận, thẻ bảo hành',
          size: 'Dài 161.9 mm x Ngang 73.2 mm x Dày 9.85 mm',
          weight: '247.98 g',
        };
      }
      if (this.selectTypePrint === this.VERTU_TEM_TYPE.META_PRO) {
        return {
          specification:
            'wifi + 4G, 6,67’’ Screen, 1080 x 2400 pixels 144Hz, Ram : 18GB, Rom : 1TB, Bluetooth v5.2, CPU Qualcomm Snapdragon 8 Gen 1',
          rearCamera: 'Chính 64 MP & Phụ 50 MP, 8 MP',
          fontCamera: '16 MP',
          composition:
            'Điện thoại, sạc, cáp, sách hướng dẫn, thẻ xác nhận, thẻ bảo hành',
          size: 'Dài 161.9 mm x Ngang 73.2 mm x Dày 9.85 mm',
          weight: '247.98 g',
        };
      }
      if (this.selectTypePrint === this.VERTU_TEM_TYPE.META_VERTU_2) {
        return {
          specification:
            'Qualcomm Snapdragon 8 Gen 2, 12GB RAM + 512GB ROM/ 18GB + 1TB ROM',
          rearCamera: 'Chính 50 MP & Phụ 50 MP, 8 MP',
          fontCamera: '16 MP',
          composition:
            'Điện thoại, sạc, cáp, pin, sách hướng dẫn, thẻ xác nhận, thẻ bảo hành',
          size: '6.78"',
          weight: '',
        };
      }

      if (this.selectTypePrint === this.VERTU_TEM_TYPE.META_WATCH) {
        return {
          specification: 'Bluetooth 5.2',
          rearCamera: 'Không',
          fontCamera: 'Không',
          composition: 'Cáp, sách hướng dẫn, thẻ xác nhận, thẻ bảo hành',
          size: '46mm-14.3mm  / 43.6mm-13.5mm',
          weight: 'Không',
        };
      }

      return {
        specification:
          'wifi + 5G, 6.67" OLED Display, RAM: 12GB, ROM: 512GB, Bluetooth Version: Bluetooth v5.2, CPU: Qualcomm@Snapdragon@ 888',
        rearCamera:
          '64MP(Main Camera) + 64MP(Ultra-Wide Camera) + 5MP(Macro Camera) + 2MP(Depth Camera)',
        fontCamera: '16MP',
        composition:
          'Điện thoại, sạc, cáp, sách hướng dẫn, thẻ xác nhận, thẻ bảo hành',
        size: '163.56mm x 75.27mm x 8.76mm',
        weight: '200g',
      };
    },
    addToList() {
      if (!this.productModel.name.trim()) {
        return makeToastFaile('Vui lòng nhập tên sản phẩm!');
      }
      const info = this.getTechnicalInfo();
      this.items.push({
        id: v4(),
        name: this.productModel.name,
        color: this.productModel.color,
        model: this.productModel.model,
        year: this.productModel.year,
        providerAddress: this.productModel.providerAddress,
        technicalInfo: info,
        type: this.selectTypePrint,
      });
    },
    showDeleteAlert: function(id) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi danh sách in không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.items = this.items.filter((item) => item.id !== id);
        }
      });
    },
  },
};
</script>

<style scoped>
.item-print {
  width: 150mm !important;
  height: 60mm !important;
  margin-top: 30px !important;
  margin-left: 30px !important;
  display: inline-table;
}

.label-color {
  color: rgb(3, 3, 3);
  font-weight: 500;
  width: 120px !important;
  font-size: 12px !important;
}

.text-content-color {
  color: rgb(3, 3, 3);
  font-weight: 500;
  white-space: pre-wrap;
  font-size: 12px !important;
  padding-right: 10px;
  width: 360px !important;
}

.text-nowrap {
  padding-left: 0px;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
